import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import Layout from "../components/layout"
import SEO from "../components/seo"
import PlatformDataLayerSamples from '../components/general/single-platform-cpt/PlatformDataLayerSamples'
import BreadcrumbSection from '../components/breadcrumb'
import { getSlug, replaceStr } from '../helper'
import { PLATFORM_SHORTCODE } from '../constants'
import PopupSchedule from '../components/popupSchedule/PopupSchedule';

const CptPlatformDatalayerSampleTemplate = ({ data, location }) => {
    const { tabs, mainElements, related } = data.wpCptPlatform.cfPlatform
    const { titleTag, metaDescription, canonicalTagOverride, robotsDirective } = data.wpCptPlatform.cfPlatform.tabs.datalayersamplesSeoFields
    const { ogTitle, ogDescription, ogImage, ogType, twitterCard } = data.wpCptPlatform.cfPlatform.tabs.datalayersamplesDefaultSeoFields
    const platformSlug = getSlug(data.wpCptPlatform.uri)
    const platformCptSlug = `${platformSlug}/${mainElements.slug}`
    const breadcrumbItems = [
        {
            name: 'Platforms',
            url: getSlug(data.wpCptPlatform.uri),
        },
        {
            name: mainElements.breadcrumbValue,
            url: platformCptSlug,
        },
        {
            name: `Data Layers`
        }
    ]

    const platformName = [mainElements.platformNamePlural, mainElements.platformNameSingular]

    if (mainElements.hideTabs && mainElements.hideTabs.includes('Data Layer Samples')) {
        if (typeof window !== "undefined") {
            window.location.href = `/${platformCptSlug}`
        }
    }

    const canonical = process.env.GATSBY_SITE_URL + location.pathname

    const [showSchedule, setShowSchedule] = useState(false)

    useEffect(() => {
        if (showSchedule) {
            document.querySelector('html').setAttribute('style', 'overflow:hidden')
        } else {
            document.querySelector('html').setAttribute('style', 'overflow:initial')
        }
    }, [showSchedule])

    useEffect(() => {
        if (window.location.href.includes("view-demo=1")) {
            setShowSchedule(true);
        }
    }, []);

    return (
        <Layout>
            <SEO
                title={replaceStr(
                    PLATFORM_SHORTCODE,
                    platformName,
                    titleTag
                )}
                description={replaceStr(
                    PLATFORM_SHORTCODE,
                    platformName,
                    metaDescription
                )}
                canonical={canonicalTagOverride || canonical}
                robotsDirective={robotsDirective}
                ogTitle={ogTitle}
                ogDescription={ogDescription}
                ogImage={ogImage}
                ogType={ogType}
                twitterCard={twitterCard}
                classBody="platform-datalayersample"
                breadcrumbItems={breadcrumbItems}
            />
            <BreadcrumbSection breadcrumbItems={breadcrumbItems} />
            <PopupSchedule showSchedule={showSchedule} setShowSchedule={setShowSchedule} />
            <PlatformDataLayerSamples tabs={tabs} mainElements={mainElements} related={related} wpCptPlatform={data.wpCptPlatform} />
        </Layout>
    )
}

export default CptPlatformDatalayerSampleTemplate

export const pageQuery = graphql`
    query PageDatalayerSamplePlatformBySlug($slugFlatform: String!,$slugTab: String!) {
        wpCptPlatform(cfPlatform: {mainElements: {slug: {eq: $slugFlatform}}, tabs: {datalayersamplesSlug: {eq: $slugTab}}}){
            cfPlatform {
                mainElements {
                    useImageInsteadOfSvg
                    breadcrumbValue
                    comingSoonMessage
                    nameOfEmailList
                    fieldGroupName
                    hideTabs
                    logoFileSvg
                    nameOfEmailList
                    platformExcerpt
                    platformNamePlural
                    platformNameSingular
                    slug
                    status
                    createAccountLiveStatus {
                        createAccountHeadline
                        createAccountSubHeadline
                    }
                    createAccountComingSoonStatus {
                        createAccountHeadline
                        createAccountSubHeadline
                    }
                    logoFileImage {
                        ...WordpressImageFields
                    }
                    iconFileSvg
                    iconUseImageInsteadOfSvg
                    iconFileImage {
                        ...WordpressImageFields
                    }
                }
                related {
                    relatedDescription
                    relatedH3
                    showRelated
                    relatedPlatforms {
                        ...WordpressCPTPlatformRelatedFields
                    }
                }
                tabs {
                    helpSlug
                    usecasesSlug
                    datalayersamplesSlug
                    listenersSlug
                    datalayersamplesH1
                    datalayersamplesH3
                    datalayersamplesH2
                    datalayersamplesIntro
                    datalayersamplesImportantNoticeHeadline
                    datalayersamplesImportantNoticeDetails
                    datalayersamplesDisplayImportantNotice
                    datalayersamplesRows {
                        codeExample
                        description
                        h4
                        importantNotice
                        layout
                        lengthOfVideoMessage
                        svg
                        videoEmbedCode
                        displayImportantNotice
                        image {
                            ...WordpressImageFields
                        }
                    }
                    datalayersamplesDefaultSeoFields {
                        ogDescription
                        ogTitle
                        ogType
                        twitterCard
                        ogImage {
                            ...WordpressImageFields
                        }
                    }
                    datalayersamplesSeoFields {
                        canonicalTagOverride
                        metaDescription
                        robotsDirective
                        titleTag
                    }
                }
            }
            uri
            
        }
    }
`
