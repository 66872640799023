import React from 'react'
import { logoType, getSlug, replaceStr } from "../../../helper"
import IntroPlatformSection from "./layout/IntroPlatformSection"
import MainPlatformSection from "./layout/MainPlatformSection"
import RelatedPlatformSection from "./layout/RelatedPlatformSection"
import { PLATFORM_SHORTCODE } from '../../../constants'

const PlatformDataLayerSamples = ({ tabs, mainElements, related, wpCptPlatform }) => {
    const platformName = [mainElements.platformNamePlural, mainElements.platformNameSingular]
    const platformSlug = `/${getSlug(wpCptPlatform.uri)}/${mainElements.slug}`

    const introHeadline = [{
        headlineValue: replaceStr(
            PLATFORM_SHORTCODE,
            platformName,
            tabs.datalayersamplesH1
        ), headlineType: 'H1'
    }, {
        headlineValue: replaceStr(
            PLATFORM_SHORTCODE,
            platformName,
            tabs.datalayersamplesH2
        ), headlineType: 'H2'
    }]

    const platformTabs = {
        listSlug: {
            overviewSlug: platformSlug,
            datalayersamplesSlug: `${platformSlug}/${tabs.datalayersamplesSlug}`,
            listenersSlug: `${platformSlug}/${tabs.listenersSlug}`,
            usecasesSlug: `${platformSlug}/${tabs.usecasesSlug}`,
            helpSlug: `${platformSlug}/${tabs.helpSlug}`,
        },
        hideTabs: mainElements.hideTabs,
    }

    const introBodyText = {
        headlines: [{
            headlineValue: replaceStr(
                PLATFORM_SHORTCODE,
                platformName,
                tabs.datalayersamplesH3
            ), headlineType: 'H3'
        }],
        body: [{
            bodyCopy: replaceStr(
                PLATFORM_SHORTCODE,
                platformName,
                tabs.datalayersamplesIntro
            )
        }]
    }


    const creatAccoutContent = {
        headline: mainElements.createAccountLiveStatus.createAccountHeadline ?
            mainElements.createAccountLiveStatus.createAccountHeadline :
            'Get started for free',
        subText: mainElements.createAccountLiveStatus.createAccountSubHeadline ?
            mainElements.createAccountLiveStatus.createAccountSubHeadline :
            'configure your measurement in minutes'
    }

    const creatAccoutComingSoonContent = {
        headline: mainElements.createAccountComingSoonStatus.createAccountHeadline ?
            mainElements.createAccountComingSoonStatus.createAccountHeadline :
            'Create a Trial Account',
        subText: mainElements.createAccountComingSoonStatus.createAccountSubHeadline ?
            mainElements.createAccountComingSoonStatus.createAccountSubHeadline :
            'configure your measurement in minutes'
    }

    const relatedPlatform = {
        titleH3: related.relatedH3,
        description: replaceStr(
            PLATFORM_SHORTCODE,
            platformName,
            related.relatedDescription
        ),
        relatedPlatforms: related.relatedPlatforms,
        showRelated: related.showRelated
    }

    const primaryLogo = logoType(
        mainElements.logoFileSvg,
        mainElements.logoFileImage,
        mainElements.useImageInsteadOfSvg,
    )

    const importantNotice = {
        showNotice: tabs.datalayersamplesDisplayImportantNotice,
        headline: tabs.datalayersamplesImportantNoticeHeadline,
        detail: tabs.datalayersamplesImportantNoticeDetails
    }

    return (
        <>
            <IntroPlatformSection
                headlines={introHeadline}
                primaryLogo={primaryLogo}
                status={mainElements.status}
                creatAccoutContent={creatAccoutContent}
                comingSoonMessage={mainElements.comingSoonMessage}
                nameOfEmailList={mainElements.nameOfEmailList}
            />
            <MainPlatformSection
                tabs={platformTabs}
                status={mainElements.status}
                creatAccoutContent={creatAccoutContent}
                comingSoonMessage={mainElements.comingSoonMessage}
                introBodyText={introBodyText}
                rows={tabs.datalayersamplesRows}
                platformName={platformName}
                importantNotice={importantNotice}
                creatAccoutComingSoonContent={creatAccoutComingSoonContent}
                nameOfEmailList={mainElements.nameOfEmailList}
            />
            <RelatedPlatformSection
                relatedPlatform={relatedPlatform}
            />
        </>
    )
}

export default PlatformDataLayerSamples
